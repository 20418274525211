<template>
  <div class="border drop-shadow-md rounded-md ma-2 pa-4">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>